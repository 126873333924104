import React from 'react';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
          <img width={50} src={require('./assets/logo-white.png')} alt='logo'/>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Coming Soon</h1>
          </div>
          <img width={150} src={require('./assets/footer_logo.png')} alt='logo text'/>
          <img width={150} src={require('./assets/CREATIVEANS_INSTAPROTECTION_footer.gif')} alt='animate avatar'/>
        </div>
        <div className="footer">
          <span>© 2024 Instaprotection Pte. Ltd. All Rights Reserved</span>
        </div>
      </div>
    );
  }
}

export default App;